import "./App.css";
import { Widget } from "./components/Widget";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Container } from "react-bootstrap";
// import { Info } from "./components/Info";

function App() {
  return (
    <div className="App">
      <header className="App-header p-5">
        <Container>
          {/* <Info /> */}
          <Row>
            <Col>
              <Widget />
            </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
}

export default App;
