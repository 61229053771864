import React, { useEffect, useState } from "react";
import { InputField } from "./InputField";
import { TagsInput } from "./TagsInput";
import { Formik, Form } from "formik";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import axios from "axios";
import { Row, Col, Button } from "react-bootstrap";
import IndiaTvLogo from '../assets/channels4_profile.jpg';

export const Widget = () => {
  const [userInputTags, setUserInputTags] = useState([]);
  const [defaultAudioTag, setDefaultAudioTag] = useState("");
  const [songsData, setSongsData] = useState([]);
  // const [promises, setPromises] = useState([]);
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);

  useEffect(() => {
    setDefaultAudioTag("Not So Welcome");

    async function getInitialSong() {
      await axios
        .get(
          `https://backend.sochcast.com/api/v1/listener/player-search?q=Not So Welcome`
        )
        .then((response) => {
          setSongsData(response.data);
        });
    }

    getInitialSong();
  }, [userInputTags]);

  const selectedTags = (tags) => {
    setUserInputTags(tags);
  };

  const initialValues = {};

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const handleOnPause = (event, currentSongName) => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      let ip = res.data.ip;

      var temp = "";
      var dType = event.path[10]["clientInformation"]["userAgentData"].mobile;

      if(dType === false) {
        temp = "Desktop";
      } else {
        temp = "Mobile";
      }

      console.log("player_version - 1.0.0");
      console.log(`sochcast_name - ${currentSongName.name}`);
      console.log(`IP Address ${ip}`);
      console.log(`${event.path[10]["clientInformation"]["userAgentData"]["brands"][2].brand}`);
      console.log("player_action - PAUSE");
      console.log(`browser_version - ${event.path[10]["clientInformation"]["userAgentData"]["brands"][2].version}`);
      console.log(`device_type - ${temp}`);
      console.log(`operating_system - ${event.path[10]["clientInformation"]["userAgentData"].platform}`);
      console.log(`page_url - ${window.document.URL}`);
    });
  };

  const handleOnPlay = (event, currentSongName) => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      let ip = res.data.ip;

      var temp = "";
      var dType = event.path[10]["clientInformation"]["userAgentData"].mobile;

      if(dType === false) {
        temp = "Desktop";
      } else {
        temp = "Mobile";
      }

      console.log("player_version - 1.0.0");
      console.log(`sochcast_name - ${currentSongName.name}`);
      console.log(`IP Address ${ip}`);
      console.log(`${event.path[10]["clientInformation"]["userAgentData"]["brands"][2].brand}`);
      console.log("player_action - PLAY");
      console.log(`browser_version - ${event.path[10]["clientInformation"]["userAgentData"]["brands"][2].version}`);
      console.log(`device_type - ${temp}`);
      console.log(`operating_system - ${event.path[10]["clientInformation"]["userAgentData"].platform}`);
      console.log(`page_url - ${window.document.URL}`);
    });
  };

  const handleNextPlay = (value) => {
    setCurrentMusicIndex(
      currentMusicIndex < value.length - 1 ? currentMusicIndex + 1 : 0
    );
  };

  const handlePreviousPlay = (value) => {
    setCurrentMusicIndex(
      currentMusicIndex === 0 ? value.length - 1 : currentMusicIndex - 1
    );
  };

  const handleSubmit = (values) => {
    console.log(values.dcpDescription);
    axios.get(`https://backend.sochcast.com/api/v1/listener/player-search?q=${values.dcpDescription}`).then((re) => {
      setSongsData(re.data);
    }).catch((err) => {
      console.log(err);
    })
  };

  const handleReset = () => {
    window.location.reload();
  }

  return (
    <>
      <Row>
        <Col>
          <Formik
            initialValues={initialValues}
            // onSubmit={handleSubmit}
          >
            {({ values }) => (
              <>
                <Form onKeyDown={onKeyDown}>
                  <div className="channel-form">
                    <label className="formLabel">
                      <p>Please enter your Description</p>
                      {/* <span style={{ fontSize: "12px" }}>To add mutltiple keywords press enter.</span> */}
                    </label>
                      <InputField
                        label=""
                        name="dcpDescription"
                        type="text"
                        data-testid="channel-name"
                        placeholder="Description"
                      />
                  </div>
                </Form>
                <Button
                  // disabled={buttonDraftStatus}
                  variant="primary"
                  className="channelDraftButton rounded-2 mx-3"
                  title="ChannelDrafButton"
                  data-testid="signUpBtton"
                  type="submit"
                  onClick={() => handleSubmit(values)}
                >
                  Submit
                </Button>

                <Button onClick={handleReset}>Clear/Reset</Button>
              </>
            )}
          </Formik>

          <h2 className="my-5">OR</h2>

          <Col>
            <Formik
                initialValues={initialValues}
                // onSubmit={handleSubmit}
              >
                {({ values }) => (
                  <>
                    <Form onKeyDown={onKeyDown}>
                      <div className="channel-form">
                        <label className="formLabel">
                          <p>Please enter your keywords</p>
                          <span style={{ fontSize: "12px" }}>To add mutltiple keywords press enter.</span>
                        </label>
                        <TagsInput selectedTags={selectedTags} tags={[]} />
                      </div>
                    </Form>
                    <Button
                      // disabled={buttonDraftStatus}
                      variant="primary"
                      className="channelDraftButton rounded-2 mx-3"
                      title="ChannelDrafButton"
                      data-testid="signUpBtton"
                      type="submit"
                      onClick={() => handleSubmit(values)}
                    >
                      Submit
                    </Button>

                    <Button onClick={handleReset}>Clear/Reset</Button>
                  </>
                )}
              </Formik>
            </Col>

        </Col>

        <Col>
          <div>
            <img
              src={songsData[currentMusicIndex]?.episode_image}
              className="img-fluid show-img-holder"
              alt={songsData[currentMusicIndex]?.name}
              style={{ width: "200px" }}
            />
          </div>
          <AudioPlayer
            src={songsData[currentMusicIndex]?.file}
            onPlay={(e) => {
              handleOnPlay(e, songsData[currentMusicIndex]);
            }}
            onPause={(e) => {
              handleOnPause(e, songsData[currentMusicIndex]);
            }}
            style={{
              width: "200px",
              height: "auto",
              backgroundColor: "#ffffff",
            }}
            footer="Sponsored by Sochcast"
            customAdditionalControls={[]}
            customVolumeControls={[]}
            showSkipControls={true}
            showJumpControls={false}
            showDownloadProgress={false}
            onClickNext={() => {
              handleNextPlay(songsData);
            }}
            onClickPrevious={() => {
              handlePreviousPlay(songsData);
            }}
            layout="stacked-reverse"
            volume="0.1"
          />
        </Col>

        <h1 className="my-5">Custom Width and Height with Brand Logo</h1>

        <Row className="my-2">
          <Col>
            <div>
              <img
                src={IndiaTvLogo}
                className="img-fluid show-img-holder"
                alt={songsData[currentMusicIndex]?.name}
                style={{ width: "200px" }}
              />
            </div>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "200px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>

          <Col>
            <div>
              <img
                src={IndiaTvLogo}
                className="img-fluid show-img-holder"
                alt={songsData[currentMusicIndex]?.name}
                style={{ width: "300px" }}
              />
            </div>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "300px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>

          <Col>
            <div>
              <img
                src={IndiaTvLogo}
                className="img-fluid show-img-holder"
                alt={songsData[currentMusicIndex]?.name}
                style={{ width: "400px" }}
              />
            </div>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "400px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>
        </Row>

        {/* optional brand image */}

        <h1 className="my-5">With Episode Image</h1>

        <Row className="my-2">
          <Col>
            <div>
              <img
                src={songsData[currentMusicIndex]?.episode_image}
                className="img-fluid show-img-holder"
                alt={songsData[currentMusicIndex]?.name}
                style={{ width: "200px" }}
              />
            </div>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "200px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>

          <Col>
            <div>
              <img
                src={songsData[currentMusicIndex]?.episode_image}
                className="img-fluid show-img-holder"
                alt={songsData[currentMusicIndex]?.name}
                style={{ width: "300px" }}
              />
            </div>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "300px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>

          <Col>
            <div>
              <img
                src={songsData[currentMusicIndex]?.episode_image}
                className="img-fluid show-img-holder"
                alt={songsData[currentMusicIndex]?.name}
                style={{ width: "400px" }}
              />
            </div>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "400px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>
        </Row>

        <h1 className="my-5">Without any image</h1>

        <Row className="my-2">
          <Col>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "200px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>

          <Col>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "300px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>

          <Col>
            <AudioPlayer
              src={songsData[currentMusicIndex]?.file}
              onPlay={(e) => {
                handleOnPlay(e, songsData[currentMusicIndex]);
              }}
              onPause={(e) => {
                handleOnPause(e, songsData[currentMusicIndex]);
              }}
              style={{
                width: "400px",
                height: "auto",
                backgroundColor: "#ffffff",
              }}
              footer="Sponsored by Sochcast"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showSkipControls={true}
              showJumpControls={false}
              showDownloadProgress={false}
              onClickNext={() => {
                handleNextPlay(songsData);
              }}
              onClickPrevious={() => {
                handlePreviousPlay(songsData);
              }}
              layout="stacked-reverse"
              volume="0.1"
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};
